// nodelist.forEach polyfill
import "nodelist-foreach-polyfill"

// UIkit
import UIkit from "uikit"

// globalni styly
import "./src/components/styles/index.scss"

/* heightSpy - BEGIN */
window.setInterval(() => {
  document.querySelectorAll("[data-height-observer]").forEach(elm => {
    let masterName = elm.getAttribute("data-height-observer")
    let masterElm = document.querySelector(
      "[data-height-observer-master='" + masterName + "']"
    )
    elm.style.height = masterElm.getBoundingClientRect().height + "px"
  })
}, 20)
/* heightSpy - END */

/* Seznam.cz retargeting - BEGIN */
export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  // provadi se jen na produkci
  if (process.env.NODE_ENV !== "production") {
    //return null
  }

  const seznamScriptID = "seznamczretargeting"
  const seznamIDdefinitionScriptID = "seznamczretargeting"

  // odstraneni retarget scriptu
  // ciste document.getElementById(ID).remove() nefunguje s IE 11
  if (document.getElementById(seznamScriptID)) {
    let seznamScriptIDElement = document.getElementById(seznamScriptID)
    seznamScriptIDElement.parentNode.removeChild(seznamScriptIDElement)
  }

  // odstraneni scriptu definujiciho retargeting ID
  // ciste document.getElementById(ID).remove() nefunguje s IE 11
  if (document.getElementById(seznamIDdefinitionScriptID)) {
    let seznamIDdefinitionScriptIDElement = document.getElementById(
      seznamIDdefinitionScriptID
    )
    seznamIDdefinitionScriptIDElement.parentNode.removeChild(
      seznamIDdefinitionScriptIDElement
    )
  }

  const setSeznamRetargeting = () => {
    const seznamScript = document.createElement("SCRIPT")
    seznamScript.setAttribute("type", "text/javascript")
    seznamScript.src = "//c.imedia.cz/js/retargeting.js"
    seznamScript.id = seznamScriptID
    document.body.appendChild(seznamScript)

    const seznamIDdefinitionScript = document.createElement("SCRIPT")
    seznamIDdefinitionScript.setAttribute("type", "text/javascript")
    seznamIDdefinitionScript.innerHTML = "var seznam_retargeting_id = 99886;"
    seznamIDdefinitionScript.id = seznamIDdefinitionScriptID
    document.body.appendChild(seznamIDdefinitionScript)
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  const delay = Math.max(32, pluginOptions.pageTransitionDelay || 0)
  setTimeout(setSeznamRetargeting, delay)

  return null
}
/* Seznam.cz retargeting - END */
