import React, { useState, useEffect } from "react"
import { Link } from "@reach/router"

// styly komponenty
import styles from "./how-does-it-work.module.scss"

export default function HowDoesItWork(props) {
  const [timeToInsertVideo, setTimeToInsertVideo] = useState(false)

  // zpozdene vlozeni YouTube videa
  useEffect(() => {
    window.setTimeout(() => {
      setTimeToInsertVideo(true)
    }, 2000)
  })

  return (
    <section
      id="scrollHowItWorks"
      className={styles.container}
      style={
        props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}
      }
    >
      <div className="uk-container">
        <div data-uk-grid>
          <div className="uk-width-1-2@s">
            <h1>
              <span>Jak</span> to
              <br />
              funguje?
            </h1>
            <p>
              Kouzlo naší sítě spočívá v komfortu pro uživatele – stačí mít s
              sebou elektrokolo. Propojovací kabel si můžete vypůjčit u obsluhy
              nabíjecí stanice.
            </p>
          </div>
          <div className="uk-width-1-2@s uk-grid-item-match">
            {timeToInsertVideo ? (
              <iframe
                width="560"
                height="315"
                src={"https://youtu.be/elaQk4DZK7A".replace("https://youtu.be/", "https://www.youtube.com/embed/")}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                data-uk-responsive
              ></iframe>
            ) : (
              ""
            )}
          </div>
        </div>
        <div data-uk-grid className={styles.ichWorkflowWrap}>
          <article
            className={`uk-width-3-4@m uk-width-1-1 ${styles.ichWorkflow}`}
          >
            <div className={`uk-clearfix ${styles.ichWorkflowItem}`}>
              <div className={styles.ichWorkflowItemNum}>01</div>
              <div className={styles.ichWorkflowItemTxt}>
                Přijeďte
                <br />
                bez nabíječky
              </div>
            </div>
            <div className={`uk-clearfix ${styles.ichWorkflowItem}`}>
              <div className={styles.ichWorkflowItemNum}>02</div>
              <div className={styles.ichWorkflowItemTxt}>Nabijte</div>
            </div>
            <div className={`uk-clearfix ${styles.ichWorkflowItem}`}>
              <div className={styles.ichWorkflowItemNum}>03</div>
              <div className={styles.ichWorkflowItemTxt}>Nic neplatíte</div>
            </div>
          </article>
          <article className={`uk-width-1-4@m uk-width-1-1 ${styles.ichLinks}`}>
            <a href="https://powerbox.one/prislusenstvi/vyber-nabijeciho-kabelu/" target="_blank">
              Podoporované typy elektrokol
            </a>
            <Link to="/myebike/post/technicka-specifikace/">Technické údaje</Link>
          </article>
        </div>
      </div>
    </section>
  )
}
