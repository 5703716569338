import React from "react"
import { Link } from "gatsby"

// styly komponenty
import styles from "./block-01.module.scss"
import arrow from "../static/arrow.svg"

export default function Block01() {
  return (
    <section className={`uk-container uk-container-expand ${styles.container}`}>
      <img src={arrow} alt="down" className={styles.arrowDown} />
      <h1>
        <span>Energie</span> pro&nbsp;vaše elektrokolo
      </h1>
      <p>
        Nabíječku nechte doma,
        <br />
        stavíme jich celou síť!
      </p>
      <div
        data-uk-grid
        className={`uk-grid uk-width-1-1 uk-width-1-2@s uk-grid uk-width-1-4@m uk-grid uk-width-1-5@l uk-grid-collapse ${styles.ichBottomBtnWrap}`}
      >
        <a
          href="#scrollHowItWorks"
          className={`uk-width-1-2@s uk-visible@s ${styles.ichHowFunBtn}`}
        >
          Jak to funguje?
          <p className="uk-heading-line uk-text-right">
            <span>ZOBRAZIT</span>
          </p>
        </a>
        <Link to="/map/" className={`uk-width-1-2@s ${styles.ichHowWhereBtn}`}>
          Kde můžu nabít?
          <p className="uk-heading-line uk-text-right">
            <span>ZOBRAZIT</span>
          </p>
        </Link>
      </div>
    </section>
  )
}
