import React, { useState, useEffect } from "react"
import Markdown from "react-markdown"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import GatsbyConfig from "../../../../gatsby-config"

// query
import getBackendUrl from "../../../libs/backend-url"
import apiQuery from "../../../libs/api"

// spolecne komponenty
import Header from "../../blocks/Header/Header"
import Footer from "../../blocks/Footer/Footer"

// seo image
import SeoImage from "./static/seo-image.jpg"

// styly komponenty
import styles from "./myebike-post.module.scss"

// inicializacni data
// TODO: dodelat, tohle nejak nefungovalo
const getInitialPost = slug => {
  if (typeof window != 'undefined') {
    let post = false
    let myebikePosts = window.localStorage.getItem('myebikePosts') ? JSON.parse(window.localStorage.getItem('myebikePosts')) : []
    myebikePosts.map(p => {
      if (p.slug == slug) {
        post = p
      }
    })
    return post
  } else {
    return false
  }
}

export default function MyEbikePost({ slug }) {

  const [post, setPost] = useState(getInitialPost(slug))

  // nacteni dat
  useEffect(() => {
    apiQuery("myebike/post/" + slug + "/")
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setPost(resultData)
      })
  }, [])

  // ulozeni do storage
  useEffect(() => {
    if (post) {
      if (typeof window != 'undefined') {
        let myebikePosts = window.localStorage.getItem('myebikePosts') ? JSON.parse(window.localStorage.getItem('myebikePosts')) : []
        let postKey = false
        myebikePosts.map((p, i) => {
          if (p.slug == post.slug) {
            postKey = i
          }
        })
        if (postKey !== false) {
          myebikePosts[postKey] = post
        } else {
          myebikePosts.push(post)
        }
        window.localStorage.setItem('myebikePosts', JSON.stringify(myebikePosts))
      }
    }
  }, [post])

  // nastaveni meta
  const [meta, setMeta] = useState({
    title: GatsbyConfig.siteMetadata.siteTitle,
    description: "",
    image: SeoImage,
  })
  useEffect(() => {
    if (post) {
      setMeta({
        title: post.title + " | " + GatsbyConfig.siteMetadata.siteTitle,
        description: post.description,
        image: post.main_image
      })
    }
  }, [post])

  return (
    <div className={styles.container}>
      {/* nastaveni meta hlavicky */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="image" content={meta.image} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={GatsbyConfig.siteMetadata.twitterUsername}
        />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
      </Helmet>

      {/* hlavicka s navigaci */}
      <Header />

      <div className="subHeaderMobile uk-hidden@s"></div>
      <div
        data-height-observer="master"
        className="subHeaderDesktop uk-visible@s"
      ></div>
      <article className={`uk-container ${styles.ichMBArticle}`}>
        <header className={`${styles.ichMBArticleHeader}`} data-uk-grid>

          {/* nadpis */}
          <div
            className={`uk-width-expand ${styles.ichMBArticleHeaderTxt}`}
            data-height-observer-master="master"
          >
            <h1 className={styles.title}>{post.title}</h1>

            {/* description */}
            <div className={styles.description}>
              <Markdown source={post.description} />
            </div>
          </div>

          {/* main image se sliderem */}
          <div className={`uk-width-1-2@s ${styles.ichMBArticleHeaderGallery}`}>
            <GallerySlider images={post.gallery} />
          </div>

        </header>

        {/* vypis bloku - BEGIN */}
        {post ? post.blocks.map((block, index) => (
          <BlockItem block={block} key={index} />
        )) : ""}
        {/* vypis bloku - END */}

      </article>

      {/* Paticka */}
      <Footer />
    </div>
  )
}

// Gallery slider
const GallerySlider = ({ images }) => {

  const [mainImageObject, setMainImageObject] = useState(false)

  useEffect(() => {
    if (images && images.length) {
      setMainImageObject(images[0])
    }
  }, [images])

  const handleClick = index => {
    setMainImageObject(images[index])
  }

  if (!mainImageObject) {
    return (<></>)
  }

  return (
    <div className={styles.mainImageWithSlider}>
      {/* main immage - BEGIN */}
      <div className={styles.mainImageWithSlider_mainImage}>
        <img
          src={`${getBackendUrl()}${mainImageObject.w400px}`}
          width="650"
          height="500"
          loading="lazy"
          className={styles.mainImageWithSlider_mainImage_image}
          alt=""
          srcSet={`
            ${getBackendUrl()}${mainImageObject.w800px} 800w,
            ${getBackendUrl()}${mainImageObject.w600px} 600w,
            `} />
      </div>
      {/* main immage - END */}

      {/* slider - BEGIN */}
      {/*
      {images.length > 1 ? (
        <div className={styles.mainImageWithSlider_slider}>
          <div data-uk-slider>
            <div className="uk-position-relative">
              <div className="uk-slider-container uk-light">
                <ul
                  className={`uk-grid-small uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m ${styles.sliderBottomWrap}`}
                >
                  {images.map((item, index) => (
                    <li
                      key={index}
                      className={styles.mainImageWithSlider_slider_item}
                      onClick={() => handleClick(index)}
                    >
                      <Img
                        className={styles.mainImageWithSlider_slider_item_image}
                        fluid={item.image.childImageSharp.fluid}
                        alt={item.title}
                      />
                    </li>
                  ))}
                </ul>
                <a
                  href="#"
                  data-uk-slider-item="previous"
                  className={styles.previousA}
                ></a>
                <a
                  href="#"
                  data-uk-slider-item="next"
                  className={styles.nextA}
                ></a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      */}
      {/* slider - BEGIN */}
    </div>
  )
}

// jeden obsahovy blok
const BlockItem = ({ block }) => {
  return (
    <div className={styles.contentBlock}>
      {/* title */}
      {block.title ? (
        <h2 className={styles.contentBlock_title}>{block.title}</h2>
      ) : (
        ""
      )}

      {/* content */}
      {block.content ? (
        <div className={styles.contentBlock_content}>
          <Markdown source={block.content} />
        </div>
      ) : (
        ""
      )}

      {/* obrazek nebo video */}
      {block.video_url || block.image ? (
        <BlockItemImageOrVideo block={block} />
      ) : (
        ""
      )}
    </div>
  )
}

// obrazek nebo video obsahoveho bloku
const BlockItemImageOrVideo = ({ block }) => {
  return (
    <div className={styles.contentBlock_medium}>
      {block.video_url ? (
        <BlockItemVideo block={block} />
      ) : (
        <BlockItemImage block={block} />
      )}
    </div>
  )
}

// obrazek obsahoveho bloku
const BlockItemImage = ({ block }) => {
  if (!block) {
    return (<></>)
  }
  return (
    <div className={styles.contentBlock_image}>
      <img
        src={`${getBackendUrl()}${block.image_sizes.w400px}`}
        loading="lazy"
        alt=""
        srcSet={`
          ${getBackendUrl()}${block.image_sizes.w800px} 800w,
          ${getBackendUrl()}${block.image_sizes.w600px} 600w,
          `}
      />
    </div>
  )
}

// video obsahoveho bloku
const BlockItemVideo = ({ block }) => {
  const [timeToInsertVideo, setTimeToInsertVideo] = useState(false)

  // zpozdene vlozeni YouTube videa
  useEffect(() => {
    if (block.video_url) {
      window.setTimeout(() => {
        setTimeToInsertVideo(true)
      }, 2000)
    }
  })

  return (
    <div className={styles.contentBlock_image}>
      {block.video_url && timeToInsertVideo ? (
        <iframe
          width="560"
          height="315"
          src={block.video_url.replace("https://youtu.be/", "https://www.youtube.com/embed/")}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          data-uk-responsive
        ></iframe>
      ) : ""}
    </div>
  )
}
