import React from "react"
import { Link } from "gatsby"

// styly komponenty
import styles from "./block-03.module.scss"
import arrow from "../static/arrowW.svg"
import bike from "../static/hp-my-bike.jpg"
export default function Block03() {
  return (
    <div className={styles.container}>
      <img src={arrow} alt="down" className={styles.arrowDown} />
      <div className=" uk-container uk-flex uk-flex-right">
        <div className={`uk-width-1-2@m ${styles.ichTextBox}`}>
          <h1>
            <span>Moje</span>
            <br />
            ELEKTROKOLO
          </h1>
          <p>
            Tento průvodce vás uvede do světa elektrokol, pomůže vám s výběrem
            elektrokola i&nbsp;jeho údržbou a&nbsp;seznámí vás
            s&nbsp;technickými parametry naší sítě nabíjecích stanic.
          </p>
          <Link
            to="/myebike/"
            className={`uk-heading-line uk-text-right ${styles.ichLinkBlog}`}
          >
            <span>zobrazit</span>
          </Link>
        </div>
      </div>
      <img src={bike} alt="bike" className={styles.myBike} />
    </div>
  )
}
