import React, { useState, useEffect } from "react"
import { Link } from "@reach/router"
import { Helmet } from "react-helmet"
import Markdown from "react-markdown"
import GatsbyConfig from "../../../../gatsby-config"

// query
import getBackendUrl from "../../../libs/backend-url"
import apiQuery from "../../../libs/api"

// spolecne komponenty
import Header from "../../blocks/Header/Header"
import Footer from "../../blocks/Footer/Footer"
import HowDoesItWork from "../../blocks/HowDoesItWork/HowDoesItWork"
import bike from "./static/bike.png"

// seo image
import SeoImage from "./static/seo-image.jpg"

// styly komponenty
import styles from "./my-ebike-list.module.scss"

// interval pro nacteni novych dat z API
const REFRESH_DATA_INTERVAL = 6 * 60 * 60 * 1000
//const REFRESH_DATA_INTERVAL = 3 * 60 * 1000

// inicializacni data
const getInitialPosts = () => {
  if (typeof window != 'undefined') {
    return window.localStorage.getItem('myebikeList') ? JSON.parse(window.localStorage.getItem('myebikeList')) : []
  } else {
    return []
  }
}

// hlavni komponenta
export default function MyEbikeList(props) {

  const [posts, setPosts] = useState(getInitialPosts())

  // nacteni dat
  useEffect(() => {
    apiQuery("myebike/posts")
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        if (typeof window != 'undefined') {
          window.localStorage.setItem('myebikeList', JSON.stringify(resultData));
        }
        setPosts(resultData)
      })
  }, [])

  // periodicke obnovovani dat
  useEffect(() => {
    const interval = setInterval(() => {
      apiQuery("myebike/posts")
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setPosts(resultData)
        })
    }, REFRESH_DATA_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // nastaveni meta pro stranku
  const meta = {
    title: "Moje elektrokolo | " + GatsbyConfig.siteMetadata.siteTitle,
    description:
      "Tento průvodce vás uvede do světa elektrokol, pomůže vám s výběrem elektrokola i jeho údržbou a seznámí vás s technickými parametry sítě nabíjecích stanic Sev.en Energy for Bikers.",
    image: SeoImage,
  }

  return (
    <div className={styles.container}>
      {/* nastaveni meta hlavicky */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="image" content={meta.image} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={GatsbyConfig.siteMetadata.twitterUsername}
        />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
      </Helmet>

      {/* hlavicka s navigaci */}
      <Header />
      <main>
        <div className="subHeaderMobile uk-hidden@s"></div>
        <div className={styles.headerWrap}>
          <div className="uk-container">
            <div className={`${styles.MBperex}`} data-uk-grid>
              <div
                className={`uk-width-1-2@m uk-width-3-5@s ${styles.MBperexImg}`}
              >
                <img
                  src={bike}
                  alt="bike"
                  className={`uk-responsive ${styles.MBperexImg}`}
                />
              </div>
              <div
                className={`uk-width-1-2@m uk-width-2-5@s ${styles.MBperexTxt}`}
              >
                <h1>
                  <span>Moje</span>
                  <br />
                  elektrokolo
                </h1>
                <p>
                  Tento průvodce vás uvede do světa elektrokol, pomůže vám s
                  výběrem elektrokola i jeho údržbou a seznámí vás s technickými
                  parametry naší sítě nabíjecích stanic.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* vypis novinek - BEGIN  */}
        <div className={`uk-container  ${styles.post_list}`}>
          <div data-uk-grid className="uk-grid-large">
            {posts.map((post, i) => (
              <ListItem key={i} post={post} />
            ))}
          </div>
        </div>
        {/* vypis novinek - END  */}
      </main>

      {/* jak to funguje */}
      <HowDoesItWork backgroundColor="#eeeeee" />

      {/* Paticka */}
      <Footer />
    </div>
  )
}

// jedna polozka vypisu postu
const ListItem = ({ post }) => {
  return (
    <div
      className={`uk-width-1-1 uk-width-1-2@s uk-width-1-3@m ${styles.post_item}`}
    >
      {post.main_image_sizes ? (
        <img
          src={`${getBackendUrl()}${post.main_image_sizes.list_w50px}`}
          width="650"
          height="500"
          loading="lazy"
          className={styles.image}
          alt={post.title}
          srcSet={`
            ${getBackendUrl()}${post.main_image_sizes.list_w650px} 650w,
            ${getBackendUrl()}${post.main_image_sizes.list_w450px} 450w,
            ${getBackendUrl()}${post.main_image_sizes.list_w250px} 250w
            `} />
      ) : ("")}
      <div className={styles.perex}>
        <h2>{post.title}</h2>
        <Markdown source={post.description} />
      </div>

      <Link
        className={`uk-heading-line uk-text-right ${styles.toLink}`}
        to={`/myebike/post/${post.slug}/`}
      >
        <span>Zobrazit</span>
      </Link>

    </div>
  )
}
