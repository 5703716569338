import React from "react"
import { Link } from "gatsby"
import logo from "./static/p4b.svg"

export default function HomePageLinkWithLogo(props) {
  return (
    <Link
      to="/"
      className={props.className ? props.className : "homepage-link-with-logo"}
    >
      <img src={logo} alt="logo" />
    </Link>
  )
}
