import React from "react"
import { Helmet } from "react-helmet"
import GatsbyConfig from "../../gatsby-config"

export default function Error404Page(props) {
  // nastaveni meta pro stranku
  const meta = {
    title: "Error 404 | " + GatsbyConfig.siteMetadata.siteTitle,
  }

  return (
    <div>
      {/* nastaveni meta hlavicky */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.title}</title>
      </Helmet>

      <h1>Error 404</h1>

    </div>
  )
}
