import React from "react"

// styly komponenty
import styles from "./footer.module.scss"

//import HomePageLinkWithLogo from "../../pages/Homepage/HomePageLinkWithLogo"
import FacebookLink from "../FacebooLink/FacbookLink"
import InstagramLink from "../InstagramLink/InstagramLink"
import logo7 from "./static/p4b_bottom.svg"


export default function Footer() {
  return (
    <footer className={`ichFooter ${styles.container}`}>
      <div className={`uk-container ${styles.topFoot}`}>
        <div className={styles.ichGrid} data-uk-grid>
          <div className={`uk-width-1-3@s   ${styles.footItem}`}>

              <img
                src={logo7}
                alt="Severní energetická"
                className={` ${styles.logo7}`}
              />

          </div>
          <div className={`uk-width-1-3@s  ${styles.footItem}`}>

          </div>
          <div className={`uk-width-1-3@s  ${styles.footItem}`}>
            <FacebookLink withLabel={true} className={styles.facebook_link} />
            <InstagramLink withLabel={true} className={styles.instagram_link} />
          </div>
        </div>
      </div>
      <div className={`uk-conatiner ${styles.bottomFoot}`}>
        © Powertica Commodities AG, odštěpný závod | IČO: 06525717 | Všchna práva vyhrazena


      </div>
    </footer>
  )
}
