import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import GatsbyConfig from "../../../gatsby-config"

// query
import apiQuery from "../../libs/api"

// MapyCZ komponenta
import MapCz from "../../components/blocks/MapyCz/MapyCz"

// spolecne komponenty
import Header from "../../components/blocks/Header/Header"
import Footer from "../../components/blocks/Footer/Footer"

// seo image
import SeoImage from "../../components/pages/Homepage/static/seo-image.jpg"

// styly komponenty
import styles from "../../components/pages/Map/map.module.scss"

// interval pro nacteni novych dat z API
const REFRESH_DATA_INTERVAL = 6 * 60 * 60 * 1000
//const REFRESH_DATA_INTERVAL = 3 * 60 * 1000

// main compoment
export default function MapPage() {
  const [mapPointsData, setMapPointsData] = useState([])

  // nacteni dat
  useEffect(() => {
    apiQuery("chargers/")
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setMapPointsData(resultData)
      })
  }, [])

  // periodicke obnovovani dat
  useEffect(() => {
    const interval = setInterval(() => {
      apiQuery("chargers/")
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setMapPointsData(resultData)
        })
    }, REFRESH_DATA_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // click element pro hledani nejblizsich nahijecek
  const showNearestChargergersElm = useRef()
  const [showNearestChargergers, setShowNearestChargergers] = useState(false)
  const handleShowNearestChargergers = () => {
    setShowNearestChargergers(new Date())
  }

  // nastaveni meta pro stranku
  const meta = {
    title: "Interaktivní mapa | " + GatsbyConfig.siteMetadata.siteTitle,
    description:
      "Interaktivní mapa zahrnuje přehled všech aktuálně spuštěných nabíjecích stanic Sev.en Energy for Bikers pro elektrokola po celé České republice. Síť nabíjecích stanic neustále rozšiřujeme.",
    image: SeoImage,
  }

  return (
    <div className={styles.container}>
      {/* nastaveni meta hlavicky */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="image" content={meta.image} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={GatsbyConfig.siteMetadata.twitterUsername}
        />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        <script src="//api.mapy.cz/loader.js" type="text/javascript" />
      </Helmet>

      {/* hlavicka s navigaci */}
      <Header />

      <section>
        <div className="subHeaderMobile uk-hidden@s"></div>
        <div className={styles.mapHeader}>
          <div className="uk-container">
            <div className="uk-flex uk-flex-right">
              <div className="uk-width-2-3@m uk-width-1-2@l">
                <h1 className={`${styles.headline}`}>
                  <span>Interaktivní</span>
                  <br />
                  mapa
                </h1>
                <button
                  ref={showNearestChargergersElm}
                  onClick={handleShowNearestChargergers}
                  className={`uk-button uk-button-default ${styles.searchChargerButton}`}
                >
                  Najdi nejbližší nabíječky
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* vykresleni mapy */}
        <div className={`uk-container ${styles.mapWrap}`}>
          <MapCz
            id="mapa"
            mapPoints={mapPointsData}
            showNearestChargergersElm={showNearestChargergersElm}
            showNearestChargergers={showNearestChargergers}
            className={styles.map}
          />
        </div>
        <div className={`uk-container ${styles.underMapWrap}`}>
          Neustále pro vás rozšiřujeme síť nabíjecích stanic po celé republice.
          Stejně tak neustále pracujeme na nových funkcích aplikace.
          <br />
          Sledujte naše sociální sítě!
        </div>
      </section>

      {/* Paticka */}
      <Footer />
    </div>
  )
}
