import React from "react"
import { FaInstagram } from "react-icons/fa"

// styly komponenty
import styles from "./instagram-link.module.scss"

const url = "https://www.instagram.com/nabijime_elektrokola/"

export default function InstagramLink(props) {
  return (
    <a
      href={url}
      className={`instagram-link ${styles.container} ${props.className}`}
      target="_blank"
      rel="noreferrer"
    >
      <FaInstagram size="2em" />
      {props.withLabel ? " Instagram" : ""}
    </a>
  )
}
