import React from "react"

// styly komponenty
import styles from "./block-04.module.scss"
import arrow from "../static/arrow.svg"
import mapFake from "../static/map-fake.png"
export default function Block04() {
  return (
    <section className={styles.container}>
      <img src={arrow} alt="down" className={styles.arrowDown} />
      <div className="uk-container">
        <div data-uk-grid>
          <div className="uk-width-1-2@s">
            <h1>O&nbsp;projektu</h1>
            <p>
              Jsme mezinárodní energetická skupina, jeden z nejvýznamnějších
              výrobců elektřiny a&nbsp;tepla v&nbsp;Česku a&nbsp;lídr
              ekologizace fosilní energetiky. V rámci CSR a&nbsp;sponzoringových
              aktivit chceme obecně dočerpávat energii tam, kde zatím chybí.
              V&nbsp;elektrokolech vidíme budoucnost turistického ruchu
              i&nbsp;městské individuální přepravy. Bouřlivě se rozvíjející trh
              se však zatím nemůže opřít o kvalitní infrastrukturu. Proto jsme
              se rozhodli vybudovat síť nabíjecích stanic, která vyniká
              především širokou kompatibilitou a uživatelským komfortem.
            </p>
          </div>
          <div className="uk-width-1-2@s   ">
            <img src={mapFake} alt="map" className={styles.mapFake} />
          </div>
        </div>
      </div>
    </section>
  )
}
