import React from "react"
import { Link } from "gatsby"
import { Match } from "@reach/router"
import { FaBars, FaTimes } from "react-icons/fa"

import FacebookLink from "../FacebooLink/FacbookLink"
import InstagramLink from "../InstagramLink/InstagramLink"

// styly komponenty
import styles from "./main-navigation.module.scss"

const menuItems = [
  { route: "/myebike/", text: "Moje elektrokolo" },
  { route: "/map/", text: "Interaktivní mapa" },
]

const NavLinkLi = props => {
  const parentProps = props
  return (
    <Match path={props.to}>
      {props =>
        props.match ? (
          <li className={parentProps.activeClassName}>
            <Link {...parentProps}>{parentProps.children}</Link>
          </li>
        ) : (
          <li>
            <Link {...parentProps}>{parentProps.children}</Link>
          </li>
        )
      }
    </Match>
  )
}

export default function MainNavigation() {
  // polozky menu
  const items = menuItems.map(item => (
    <NavLinkLi
      key={item.route}
      to={item.route}
      activeClassName="uk-active"
      partiallyActive={true}
    >
      {item.text}
    </NavLinkLi>
  ))

  return (
    <div className="uk-navbar-right">
      {/* spoustec offcanvas menu - BEGIN */}
      <button
        data-uk-toggle="target: #mobile-menu"
        type="button"
        className={`uk-hidden@s ${styles.ichToggleMenuBtn}`}
      >
        <FaBars size="2em" />
      </button>
      {/* spoustec offcanvas menu - END */}
      <ul
        className={`uk-navbar-nav uk-visible@s ich-mainMenu ${styles.customMainNavStyle}`}
      >
        {items}

        <li className={styles.mainMenuSocialFBDesktop}>
          <FacebookLink className={styles.facebook_link} />
        </li>
        <li className={styles.mainMenuSocialINGDesktop}>
          <InstagramLink className={styles.instagram_link} />
        </li>
      </ul>

      {/* offcanvas menu - BEGIN */}
      <div id="mobile-menu" data-uk-offcanvas="flip: true;mode: push">
        <div
          className={`uk-offcanvas-bar ichGOffcanvasBar ${styles.ichOffcanvasBar}`}
        >
          <button className="uk-offcanvas-close" type="button">
            <FaTimes size="2em" />
          </button>
          <ul className="uk-nav">
            {items}
            <li>
              <FacebookLink className={styles.facebook_link} />
            </li>
            <li>
              <InstagramLink className={styles.instagram_link} />
            </li>
          </ul>
        </div>
      </div>
      {/* offcanvas menu - END */}
    </div>
  )
}
