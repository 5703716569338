import React from "react"

// styly komponenty
import styles from "./header.module.scss"

import HomePageLinkWithLogo from "../../pages/Homepage/HomePageLinkWithLogo"
import MainNavigation from "../MainNavigation/MainNavigation"

export default function Header() {
  return (
    <header className={styles.header}>
      <nav
        className="uk-navbar-container uk-navbar-transparent ich-header"
        data-uk-navbar
      >
        <MainNavigation />
        <HomePageLinkWithLogo className={styles.homepage_link_with_logo} />
      </nav>
    </header>
  )
}
