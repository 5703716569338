import React, { useState, useEffect, useRef } from "react"
import opening_hours from "opening_hours"
import gpsPointsDistance from "../../../libs/gps-points-distanc"

// API URL
const API_URL = "//api.mapy.cz/loader.js"

// defaultni center mapy
const DEFAULT_MAP_CENTER = [49.7426, 15.339417]

// defaultni zoom mapy
const DEFAULT_MAP_ZOOM = 10

// interval pro refresh oteviracek v ms
//const REFRESH_OPENINGS_INTERVAL = 5 * 60 * 1000
const REFRESH_OPENINGS_INTERVAL = 5 * 1000

// marker icons
import MARKER_ICON_OPEN from "./static/marker-open.png"
import MARKER_ICON_CLOSE from "./static/marker-close.png"
import MARKER_ICON_UNDER_CONSTRUCTION from "./static/marker-under-construction.png"

// vraci jednu z moznosti podle toho jestli je nabijecka otevrena
const getOptionByChargerOpenedStatus = (
  markerData,
  trueOpt = true,
  falseOpt = false,
  nullOpt = null
) => {
  // v rekonstrukci
  if (markerData.under_construction) return nullOpt
  try {
    const oh = new opening_hours(markerData.opening_hours)
    return oh.getState() ? trueOpt : falseOpt;
  }
  // nezadano
  catch (err) {
    return nullOpt
  }
}

// vraci ikonu markeru podle dat markeru
const getMarkerIcon = markerData => {
  let icon = getOptionByChargerOpenedStatus(
    markerData,
    MARKER_ICON_OPEN,
    MARKER_ICON_CLOSE,
    MARKER_ICON_UNDER_CONSTRUCTION
  )
  let container = window.JAK.mel("div", {}, { minWidth: "60px" })
  let image = window.JAK.mel("img", { src: icon })
  container.appendChild(image)
  return container
}

// formatuje OSM opening hours retezec
const formatOSMOpeningHours = osmString => {
  if (!osmString || osmString.trim() == '') return ''
  const oh = new opening_hours(osmString);
  let prettyString = oh.prettifyValue({conf: { locale: 'cs' },});
  // velke prvni pismeno v nazvu dne
  prettyString = prettyString.replace(/(^|\s)[a-záčďéěíňóřšťúůýž]/g, function(firstLetter) {
    return firstLetter.toUpperCase();
  })
  // dvojtecka za nazvem dne
  prettyString = prettyString.replace(/(\b\w{2}\b)(\s)/g, '$1:$2')
  // stredniky > br
  prettyString = prettyString.replace(/;/g, '<br />');
  // pomlcka obalena nedelitelnou mezerou
  prettyString = prettyString.replace(/-/g, ' - ')
  return prettyString
}

// vraci card element (vizitku) pro marker
const getCardElm = markerData => {
  let card = new window.SMap.Card()
  card.getHeader().innerHTML = `<div class='markerCardHeader'>${markerData.title}<strong></strong></div>`
  card.getBody().innerHTML = `
    <div class="markerCardBody">
      <div class="markerCardBody_address">
        ${markerData.address_street} ${markerData.address_house_number}<br />
        ${markerData.address_zip} ${markerData.address_city}
      </div>
      ${
        markerData.opening_hours_human_html
          ? '<div class="markerCardBody_opennings">' +
            markerData.opening_hours_human_html +
            '</div>'
          : '<div class="markerCardBody_opennings">' +
            formatOSMOpeningHours(markerData.opening_hours) +
            '</div>'
      }
      ${getOptionByChargerOpenedStatus(
        markerData,
        "<div class='markerCardBody_isOpened'>OTEVŘENO</div>",
        "<div class='markerCardBody_isClosed'>ZAVŘENO</div>",
        "<div class='markerCardBody_isUnderConstruction'>VE VÝSTAVBĚ</div>"
      )}
      ${getOptionByChargerOpenedStatus(
        markerData,
        `<button type='button' onClick='navigovat(${markerData.lng}, ${markerData.lat}); false;'>NAVIGOVAT</button>`,
        "",
        ""
      )}
    </div>
  `
  return card
}

if (typeof window != "undefined") {
  window.navigovat = (destLng, destLat) => {
    let smapUrl = new SMap.URL.Route()
    smapUrl.addDestination(SMap.Coords.fromWGS84(destLng, destLat))
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          smapUrl.addStart(
            SMap.Coords.fromWGS84(pos.coords.longitude, pos.coords.latitude),
            { type: "bike" }
          )
          smapUrl.addDestination(SMap.Coords.fromWGS84(destLng, destLat))
          window.open(smapUrl.toString(), "_blank")
        },
        () => {
          window.open(smapUrl.toString(), "_blank")
        }
      )
    } else {
      window.open(smapUrl.toString(), "_blank")
    }
  }
}

// prehozeni LatLng na LngLat
// z nejakeho obskurniho duvodu maji mapy.cz prohozene poradi parametru
const latLng2LngLat = latLng => [latLng[1], latLng[0]]

// main component
export default function MapyCz(props) {
  const mapContainerId = props.id ? props.id : "map"
  const className = props.className ? props.className : "map"

  // lifecycle states
  const [apiLoaded, setApiLoaded] = useState(false)
  const [loaderLoad, setLoaderLoad] = useState(false)
  const [mapObject, setMapObject] = useState(false)
  const [markerLayer, setMarkerLayer] = useState(false)

  // load API - BEGIN
  useEffect(() => {
    let script = document.createElement("script")
    script.src = API_URL
    script.async = true
    script.addEventListener("load", () => setApiLoaded(true))
    document.body.appendChild(script)
  }, [])
  // load API - END

  // loader load - BEGIN
  useEffect(() => {
    if (apiLoaded) {
      window.Loader.async = true
      window.Loader.load(null, null, () => setLoaderLoad(true))
    }
  }, [apiLoaded])
  // loader load - END

  // nastaveni "me pozice"
  const myPositionMarker = useRef(false)
  const setMyPositionMarker = markerLayer => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        let position = SMap.Coords.fromWGS84(
          pos.coords.longitude,
          pos.coords.latitude
        )
        myPositionMarker.current = new SMap.Marker(position, "MyPosition", {})
        markerLayer.addMarker(myPositionMarker.current)
      })
    }
  }

  // vykresleni markeru
  const markers = useRef([])
  const drawMarkers = () => {
    const firstRender = markers.current.length == 0 ? true : false
    // TODO: tohle udelat nejak chytreji at se nemusi mazat vsechny - porovnanvani zmen?
    markerLayer.removeAll()
    props.mapPoints.map(item => {
      let loc = window.SMap.Coords.fromWGS84(
        ...latLng2LngLat([item.lat, item.lng])
      )
      let options = {
        url: getMarkerIcon(item),
        title: item.title,
      }
      let card = getCardElm(item)
      let marker = new window.SMap.Marker(loc, item.id, options)
      marker.decorate(window.SMap.Marker.Feature.Card, card)
      markerLayer.addMarker(marker)
      marker.markerData = item
      markers.current.push(marker)
    })
    if (firstRender) {
      setMarkersInMapViewport()
    }
  }

  // refresh oteviracich dob
  const refreshOpenings = () => {
    if (markers) {
      markers.current.map(marker => {
        let actualIconSrc = getMarkerIcon(marker.markerData).childNodes[0].src
        if (marker._options.url.childNodes[0].src != actualIconSrc) {
          marker._options.url.childNodes[0].src = actualIconSrc
          marker._card = getCardElm(marker.markerData)
        }
      })
    }
  }

  // init map object - BEGIN
  useEffect(() => {
    if (loaderLoad) {
      let center = window.SMap.Coords.fromWGS84(
        ...latLng2LngLat(DEFAULT_MAP_CENTER)
      )
      let mapObject = new window.SMap(
        window.JAK.gel(mapContainerId),
        center,
        DEFAULT_MAP_ZOOM
      )
      mapObject.addDefaultLayer(window.SMap.DEF_BASE).enable()
      mapObject.addDefaultControls()
      let markerLayer = new window.SMap.Layer.Marker()
      mapObject.addLayer(markerLayer)
      markerLayer.enable()
      setMarkerLayer(markerLayer)
      setMapObject(mapObject)

      // inicializace markeru "moje pozice"
      setMyPositionMarker(markerLayer)
    }
  }, [loaderLoad])
  // init map object - END

  // inicializace vykresleni markeru
  useEffect(() => {
    if (props.mapPoints.length > 0 && markerLayer) {
      drawMarkers()
    }
  }, [props.mapPoints, markerLayer])

  // refresh oteviracich dob
  useEffect(() => {
    const interval = setInterval(() => {
      refreshOpenings()
    }, REFRESH_OPENINGS_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // pokud neni zapnuta geolokace nezobrazi se tlacitko pro zobrazeni nejblizsich nabijecek
  useEffect(() => {
    props.showNearestChargergersElm.current.style.display = navigator.geolocation
      ? ""
      : "none"
  }, [])

  // zobrazeni nejblizsich nabijecek
  useEffect(() => {
    if (
      props.showNearestChargergers &&
      myPositionMarker.current &&
      markers.current.length
    ) {
      let myPositionCords = myPositionMarker.current.getCoords()
      let markersWithDistanc = []
      markers.current.map(marker => {
        let markerCords = marker.getCoords()
        let distanc = gpsPointsDistance(
          myPositionCords.y,
          myPositionCords.x,
          markerCords.y,
          markerCords.x,
          "K"
        )
        markersWithDistanc.push({ marker: marker, distanc: distanc })
      })
      markersWithDistanc.sort((a, b) => a.distanc - b.distanc)
      markersWithDistanc = markersWithDistanc.slice(0, 5)
      let nearestChargergers = markersWithDistanc.map(item => item.marker)
      setMarkersInMapViewport(true, nearestChargergers)
    }
  }, [props.showNearestChargergers])

  // funkce pro zobrazeni vsech markeru
  const setMarkersInMapViewport = (
    includeMyPosition = false,
    markers = markerLayer.getMarkers()
  ) => {
    let coordinates = []
    markers.map(marker => {
      coordinates.push(marker.getCoords())
    })
    if (includeMyPosition) {
      coordinates.push(myPositionMarker.current.getCoords())
    }
    let computedCenter = mapObject.computeCenterZoom(coordinates)
    mapObject.setCenterZoom(...computedCenter)
  }

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        /* FIX: UIkit vers. mapy.cz css image problem */
        img[src^="http://mapserver.mapy.cz"],
        img[src^="http://api.mapy.cz"],
        img[src^="https://mapserver.mapy.cz"],
        img[src^="https://api.mapy.cz"] {
          max-width: none
        }
      `,
        }}
      ></style>
      <div id={mapContainerId} className={className}></div>
    </>
  )
}
