const getBackendUrl = () => {
  // produkce (nebo yarn build!)
  if (process.env.NODE_ENV != 'development') {
    return 'https://backend.power4bikers.cz/'
  }

  // develop
  else {
    return 'http://localhost:8001'
  }
}
export default getBackendUrl
